<template>
  <article class="product-card">
    <div class="product-card__preview">
      <nuxt-link
        :to="
          localePath({
            name: 'catalog.category.product',
            params: {
              category: categoryLink,
              product: item.code,
            },
          })
        "
        class="product-card__preview-link bg-image"
      >
        <CustomImage
          :alt="item.image.caption || item.name"
          :height="imageSizes[1]"
          :image-id="item.image.id"
          :width="imageSizes[0]"
          class="product-card__picture"
        />
        <ul v-if="visibleBadges" class="product-card__tags">
          <li
            v-for="(badge, index) in badges"
            :key="`badge-${index}`"
            class="product-card__tag font_uppercase font_bold"
          >
            {{ badge }}
          </li>
        </ul>
      </nuxt-link>
      <ul v-if="visibleColors" class="product-card__colors">
        <li
          v-for="variantProduct in item.variantGroups.products"
          :key="variantProduct.code"
          class="product-card__colors-item"
        >
          <div
            v-if="variantProduct.code === item.code"
            class="product-card__colors-link product-card__colors-link--current"
          >
            <span :style="styleColor(variantProduct.props)" class="product-card__colors-circle" />
          </div>
          <nuxt-link
            v-else
            :to="
              localePath({
                name: 'catalog.category.product',
                params: {
                  category: categoryLink,
                  product: variantProduct.code,
                },
              })
            "
            class="product-card__colors-link"
          >
            <span :style="styleColor(variantProduct.props)" class="product-card__colors-circle" />
          </nuxt-link>
        </li>
      </ul>
      <button
        v-if="pageType === 'catalog'"
        :aria-label="$t('catalog.toFavorites')"
        :class="{
          'product-card__favorite--active': productInFavorites(item.productId),
        }"
        class="product-card__favorite"
        data-test="product-card-add-to-favorite"
        @click="toggleToFavoritesItem"
      >
        <svg
          class="product-card__favorite-ico"
          height="18"
          viewBox="0 0 20 18"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.32751 2.48781L9.88192 3.09737L10.4368 2.48825C11.4546 1.37099 12.7047 0.75 14.1583 0.75C16.5761 0.75 19.0178 2.49038 19.0178 5.88153C19.0178 7.45322 18.2898 9.15572 16.7282 10.9984C15.2113 12.7883 12.937 14.6751 9.88303 16.655C6.83005 14.6756 4.55626 12.7889 3.03966 10.999C1.47806 9.15603 0.75 7.45324 0.75 5.88153C0.75 2.49038 3.19174 0.75 5.60953 0.75C7.06116 0.75 8.31009 1.36915 9.32751 2.48781Z"
            stroke-width="1.5"
          />
        </svg>
      </button>
      <button
        v-if="pageType === 'promo'"
        :aria-label="$t('catalog.deleteFromPromo')"
        class="product-card__remove-from-promo"
        data-test="product-card-remove-from-promo"
        @click="removeFromPromo"
      >
        <svg-icon height="16" name="close" width="16" />
      </button>
    </div>

    <nuxt-link
      :to="
        localePath({
          name: 'catalog.category.product',
          params: {
            category: categoryLink,
            product: item.code,
          },
        })
      "
      class="product-card__info"
    >
      <template v-if="item.price">
        <p v-if="item.price.value && item.price.value.from" class="product-card__price">
          {{ $t('shared.priceFrom') }} {{ item.price.value.from | price }}
        </p>

        <div v-else-if="item.price.value" class="product-card__price-wrapper">
          <div
            :class="{
              'product-card__price--sale': item.oldPrice && item.oldPrice.value,
            }"
            class="product-card__price font font_m font_bold"
          >
            {{ item.price | price }}
          </div>
          <div v-if="item.oldPrice && item.oldPrice.value" class="product-card__price-old font font_sm font_grey">
            {{ item.oldPrice | price }}
          </div>
        </div>
      </template>

      <h3 class="product-card__name font font_m">
        {{ item.name }}
      </h3>
      <!-- Отзывы реализуются через плагин -->
      <!--        <small class="product-card__warning">Распродано</small>-->
    </nuxt-link>

    <footer v-if="pageType === 'catalog'" class="product-card__footer font">
      <div v-if="pageType && item.price && item.price.value" class="product-card__action product-card__action--full">
        <Button
          v-if="productInCart(item.id)"
          :to="localePath({ name: 'cart' })"
          class="product-card__add-to-cart font_sm font_uppercase"
          theme="border"
        >
          {{ $t('shared.inCart') }}
        </Button>
        <Button
          v-else-if="item.variantGroups"
          :to="
            localePath({
              name: 'catalog.category.product',
              params: {
                category: categoryLink,
                product: item.code,
              },
            })
          "
          class="product-card__add-to-cart font_sm font_uppercase"
          data-test="product-card-add-more"
          theme="border"
        >
          {{ $t('shared.detail') }}
        </Button>
        <Button
          v-else
          :disabled="disabledButtonToCart"
          class="product-card__add-to-cart font_sm font_uppercase"
          data-test="product-card-add-to-cart"
          theme="border"
          @click="onAddCartButtonClick"
        >
          {{ $t('shared.buy') }}
        </Button>
      </div>
      <div
        v-else
        class="
          product-card__action product-card__action--full product-card__action--out-of-stock
          font_sm font_uppercase
        "
      >
        {{ $t('shared.product.outOfStock') }}
      </div>
    </footer>
  </article>
</template>

<script>
import _debounce from 'lodash/debounce';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Button from '@/components/elements/Button';
import { getImageAllFormats } from '@/plugins/helpers/file';
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'ProductCard',
  components: {
    CustomImage,
    Button,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    pageType: {
      type: String,
      default: 'catalog',
    },
  },
  computed: {
    ...mapGetters('cart', ['productInCart', 'getProductCount', 'getProductCountWithBundle']),
    ...mapGetters('catalog', ['formattedAllCategories']),
    ...mapGetters('favorites', ['productInFavorites']),
    ...mapState('auth', ['hasSession']),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
    imageSizes() {
      return this.mqIsMobile ? [280, 280] : [288, 288];
    },
    image() {
      return getImageAllFormats(this.item.image.id, this.imageSizes[0], this.imageSizes[1]);
    },
    categoryLink() {
      const categoryCodes = this.item.categoryCodes;
      return categoryCodes && categoryCodes.length > 0 && categoryCodes[categoryCodes.length - 1];
    },
    disabledButtonToCart() {
      return (
        this.getProductCount(this.item.id) >= this.item.stock.qty ||
        this.getProductCountWithBundle(this.item.id) >= this.item.stock.qty
      );
    },
    visibleBadges() {
      return this.item.badges?.length;
    },
    visibleColors() {
      return this.item.variantGroups?.characteristics.some((item) => item.isColor);
    },
    badges() {
      return this.item.badges.map(el => typeof el === 'object' ? this.$options.filters.price(el) : el)
    },
  },
  methods: {
    ...mapActions({
      addToCartProduct: 'cart/addToCartProduct',
      changeCartProductItemCount: 'cart/changeCartProductItemCount',
      addFavoritesItem: 'favorites/addFavoritesItem',
      deleteFavoritesItem: 'favorites/deleteFavoritesItem',
    }),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
    onAddCartButtonClick() {
      try {
        // eslint-disable-next-line no-undef
        rrApi.addToBasket(this.item.id);
      } finally {
        this.addToCartProduct(this.item);
      }
    },
    styleColor(props) {
      const characteristics = this.item.variantGroups.characteristics;
      let color;
      props.forEach((itemProp) => {
        const codeItem = itemProp.split('#')[0];
        const characteristicItem = characteristics.find((item) => item.code === codeItem && item.isColor);
        if (characteristicItem) {
          color = itemProp.split('#')[1];
        }
      });

      return {
        backgroundColor: `#${color}`,
      };
    },
    onChangeCount: _debounce(function (count) {
      this.changeCartProductItemCount({
        product: this.item,
        count,
      });
    }, 300),
    changeCount(count) {
      this.changeCartProductItemCount({
        product: this.item,
        count,
      });
    },
    toggleToFavoritesItem() {
      if (this.hasSession) {
        if (this.productInFavorites(this.item.productId)) {
          this.deleteFavoritesItem(this.item.productId);
        } else {
          this.addFavoritesItem(this.item.productId);
        }
        this.$emit('toggle-favorite', this.item.productId);
      } else {
        this.setIsOpenedAuthModal(true);
      }
    },
    removeFromPromo() {
      this.$emit('remove-item-from-promo', this.item.productId);
    },
  },
};
</script>

<style scoped>
.product-card {
  position: relative;
  background-color: var(--color-white-basic);
  text-align: center;
}

.product-card__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 8px;
}

.product-card__store {
  display: grid;
  grid-template-columns: 26px 1fr;
  column-gap: 8px;
  color: inherit;
  text-decoration: none;
}

.product-card__store-name,
.product-card__store-type {
  font-size: 11px;
  line-height: 13px;
  margin: 0;
}

.product-card__store-picture {
  width: 26px;
  height: 26px;
  grid-row-start: 1;
  grid-row-end: 3;
  overflow: hidden;
  border-radius: 50%;
}

.product-card__store-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.product-card__store-name {
  font-weight: 500;
}

.product-card__store-type {
  color: var(--color-gray-black-semifade);
  letter-spacing: 0.07px;
}

.product-card__preview-link {
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 2rem 0.8rem;
}

.product-card__preview {
  position: relative;
  text-align: center;
}

.product-card__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;

  position: absolute;
  top: 8px;
  left: 8px;
  list-style: none;
  margin: 0;
  padding: 0;

  max-width: 75%;
}

.product-card__tag {
  font-size: 1rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
  pointer-events: none;
}

.product-card__colors {
  position: absolute;
  z-index: 1;
  right: 2px;
  bottom: 2px;
  max-width: 50%;
}

.product-card__preview >>> .product-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card__info {
  display: block;
  padding: 16px 0;
  transition: 0.2s;
}

.product-card__price-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card__price {
  font-weight: 700;
  font-size: 16px;
  line-height: 116%;
  letter-spacing: -0.01em;
  color: var(--color-basic-black);
}

.product-card__price-old {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration: line-through;
}

.product-card__name {
  margin: 4px 0 0;
  font-size: 14px;
  line-height: 1.2 !important;
  color: var(--color-basic-black);

  --max-lines: 3;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}

.product-card__actions {
  display: flex;
  list-style: none;
  padding: 0;
}

.product-card__action {
  flex-shrink: 1;
}

.product-card__action:not(:first-child) {
  margin-left: 4px;
}

.product-card__action--full {
  width: 100%;
}

.product-card__action--out-of-stock {
  display: flex;
  align-items: center;
}

.product-card__remove-from-promo,
.product-card__favorite {
  position: absolute;
  top: 8px;
  right: 8px;
  flex-shrink: 0;
  border: none;
}

.product-card__favorite-ico {
  transition: 0.3s;
}

.product-card__favorite-ico {
  fill: var(--color-transparent);
  stroke: var(--color-basic-black);
}

.product-card__favorite--active .product-card__favorite-ico {
  fill: var(--color-button-bg);
}

.product-card__warning {
  display: block;
  font-size: 11px;
  line-height: 13px;
  color: var(--color-accent-third);
  margin-bottom: 4px;
}

.product-card__delivery {
  display: block;
  margin-top: 8px;
  font-size: 11px;
  line-height: 13px;
  color: var(--color-basic-black);
}

.product-card__delivery-express {
  font-weight: 700;
}

.product-card__delivery-express::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 12px;
  margin-right: 5px;
  background-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.885254 8.22317H4.16162L2.44824 12.7295C2.20117 13.3794 2.87256 13.7178 3.30762 13.1968L8.60352 6.76761C8.71094 6.6387 8.76465 6.5098 8.76465 6.37015C8.76465 6.12308 8.57129 5.94046 8.30811 5.94046H5.03174L6.73975 1.42874C6.98682 0.784209 6.31543 0.440459 5.88574 0.961456L0.584473 7.39065C0.477051 7.52493 0.428711 7.64847 0.428711 7.78812C0.428711 8.04056 0.62207 8.22317 0.885254 8.22317Z' fill='%2312121D'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
}

.product-card__colors {
  list-style: none;
  background: var(--color-gray-pale);
  border-radius: 4px;
  padding: 2px;
  display: flex;
}

.product-card__colors-item:not(:last-child) {
  margin-right: 2px;
}

.product-card__colors-link {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-card__colors-link--current {
  background: var(--color-white-basic);
}

.product-card__colors-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.product-card__colors-circle--dark {
  border: 1px solid var(--color-gray-faint);
}

.product-card__footer {
  display: none;
}

@media all and (min-width: 768px) {
  .product-card__tags {
    gap: 0.8rem;
  }

  .product-card__tag {
    font-size: 1.4rem;
  }
}

@media all and (min-width: 1280px) {
  .product-card {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .product-card__header {
    margin-bottom: 8px;
  }

  .product-card__store {
    grid-template-columns: 32px 1fr;
  }

  .product-card__store-name,
  .product-card__store-type {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }

  .product-card__store-picture {
    width: 32px;
    height: 32px;
  }

  .product-card__preview-link {
    padding: 32px;
  }

  .product-card__name {
    margin-top: 8px;
  }

  .product-card__warning {
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.03em;
  }

  .product-card__footer {
    margin-top: auto;
  }

  .product-card__action:not(:first-child) {
    margin-left: 8px;
  }

  .product-card__delivery {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: var(--color-basic-secondary);
  }

  .product-card__colors-link {
    width: 20px;
    height: 20px;
  }

  .product-card__colors-circle {
    width: 12px;
    height: 12px;
  }

  .product-card__favorite {
    opacity: 0;
    transition: 0.2s;
  }

  .product-card__favorite--active {
    opacity: 1;
  }

  .product-card__footer {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.2s;
  }

  .product-card__add-to-cart {
    width: 130px;
    height: 40px;
  }

  .product-card__picture {
    transition: var(--basic-animation-time);
  }

  .product-card:hover .product-card__favorite {
    opacity: 1;
  }

  .product-card:hover .product-card__footer {
    opacity: 1;
  }

  .product-card:hover .product-card__info {
    background: var(--color-product-bg);
  }

  .product-card:hover .product-card__picture {
    opacity: 0.2;
  }
}
</style>
