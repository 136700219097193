var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"product-card"},[_c('div',{staticClass:"product-card__preview"},[_c('nuxt-link',{staticClass:"product-card__preview-link bg-image",attrs:{"to":_vm.localePath({
          name: 'catalog.category.product',
          params: {
            category: _vm.categoryLink,
            product: _vm.item.code,
          },
        })}},[_c('CustomImage',{staticClass:"product-card__picture",attrs:{"alt":_vm.item.image.caption || _vm.item.name,"height":_vm.imageSizes[1],"image-id":_vm.item.image.id,"width":_vm.imageSizes[0]}}),_vm._v(" "),(_vm.visibleBadges)?_c('ul',{staticClass:"product-card__tags"},_vm._l((_vm.badges),function(badge,index){return _c('li',{key:`badge-${index}`,staticClass:"product-card__tag font_uppercase font_bold"},[_vm._v("\n          "+_vm._s(badge)+"\n        ")])}),0):_vm._e()],1),_vm._v(" "),(_vm.visibleColors)?_c('ul',{staticClass:"product-card__colors"},_vm._l((_vm.item.variantGroups.products),function(variantProduct){return _c('li',{key:variantProduct.code,staticClass:"product-card__colors-item"},[(variantProduct.code === _vm.item.code)?_c('div',{staticClass:"product-card__colors-link product-card__colors-link--current"},[_c('span',{staticClass:"product-card__colors-circle",style:(_vm.styleColor(variantProduct.props))})]):_c('nuxt-link',{staticClass:"product-card__colors-link",attrs:{"to":_vm.localePath({
              name: 'catalog.category.product',
              params: {
                category: _vm.categoryLink,
                product: variantProduct.code,
              },
            })}},[_c('span',{staticClass:"product-card__colors-circle",style:(_vm.styleColor(variantProduct.props))})])],1)}),0):_vm._e(),_vm._v(" "),(_vm.pageType === 'catalog')?_c('button',{staticClass:"product-card__favorite",class:{
        'product-card__favorite--active': _vm.productInFavorites(_vm.item.productId),
      },attrs:{"aria-label":_vm.$t('catalog.toFavorites'),"data-test":"product-card-add-to-favorite"},on:{"click":_vm.toggleToFavoritesItem}},[_c('svg',{staticClass:"product-card__favorite-ico",attrs:{"height":"18","viewBox":"0 0 20 18","width":"20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.32751 2.48781L9.88192 3.09737L10.4368 2.48825C11.4546 1.37099 12.7047 0.75 14.1583 0.75C16.5761 0.75 19.0178 2.49038 19.0178 5.88153C19.0178 7.45322 18.2898 9.15572 16.7282 10.9984C15.2113 12.7883 12.937 14.6751 9.88303 16.655C6.83005 14.6756 4.55626 12.7889 3.03966 10.999C1.47806 9.15603 0.75 7.45324 0.75 5.88153C0.75 2.49038 3.19174 0.75 5.60953 0.75C7.06116 0.75 8.31009 1.36915 9.32751 2.48781Z","stroke-width":"1.5"}})])]):_vm._e(),_vm._v(" "),(_vm.pageType === 'promo')?_c('button',{staticClass:"product-card__remove-from-promo",attrs:{"aria-label":_vm.$t('catalog.deleteFromPromo'),"data-test":"product-card-remove-from-promo"},on:{"click":_vm.removeFromPromo}},[_c('svg-icon',{attrs:{"height":"16","name":"close","width":"16"}})],1):_vm._e()],1),_vm._v(" "),_c('nuxt-link',{staticClass:"product-card__info",attrs:{"to":_vm.localePath({
        name: 'catalog.category.product',
        params: {
          category: _vm.categoryLink,
          product: _vm.item.code,
        },
      })}},[(_vm.item.price)?[(_vm.item.price.value && _vm.item.price.value.from)?_c('p',{staticClass:"product-card__price"},[_vm._v("\n        "+_vm._s(_vm.$t('shared.priceFrom'))+" "+_vm._s(_vm._f("price")(_vm.item.price.value.from))+"\n      ")]):(_vm.item.price.value)?_c('div',{staticClass:"product-card__price-wrapper"},[_c('div',{staticClass:"product-card__price font font_m font_bold",class:{
            'product-card__price--sale': _vm.item.oldPrice && _vm.item.oldPrice.value,
          }},[_vm._v("\n          "+_vm._s(_vm._f("price")(_vm.item.price))+"\n        ")]),_vm._v(" "),(_vm.item.oldPrice && _vm.item.oldPrice.value)?_c('div',{staticClass:"product-card__price-old font font_sm font_grey"},[_vm._v("\n          "+_vm._s(_vm._f("price")(_vm.item.oldPrice))+"\n        ")]):_vm._e()]):_vm._e()]:_vm._e(),_vm._v(" "),_c('h3',{staticClass:"product-card__name font font_m"},[_vm._v("\n      "+_vm._s(_vm.item.name)+"\n    ")])],2),_vm._v(" "),(_vm.pageType === 'catalog')?_c('footer',{staticClass:"product-card__footer font"},[(_vm.pageType && _vm.item.price && _vm.item.price.value)?_c('div',{staticClass:"product-card__action product-card__action--full"},[(_vm.productInCart(_vm.item.id))?_c('Button',{staticClass:"product-card__add-to-cart font_sm font_uppercase",attrs:{"to":_vm.localePath({ name: 'cart' }),"theme":"border"}},[_vm._v("\n        "+_vm._s(_vm.$t('shared.inCart'))+"\n      ")]):(_vm.item.variantGroups)?_c('Button',{staticClass:"product-card__add-to-cart font_sm font_uppercase",attrs:{"to":_vm.localePath({
            name: 'catalog.category.product',
            params: {
              category: _vm.categoryLink,
              product: _vm.item.code,
            },
          }),"data-test":"product-card-add-more","theme":"border"}},[_vm._v("\n        "+_vm._s(_vm.$t('shared.detail'))+"\n      ")]):_c('Button',{staticClass:"product-card__add-to-cart font_sm font_uppercase",attrs:{"disabled":_vm.disabledButtonToCart,"data-test":"product-card-add-to-cart","theme":"border"},on:{"click":_vm.onAddCartButtonClick}},[_vm._v("\n        "+_vm._s(_vm.$t('shared.buy'))+"\n      ")])],1):_c('div',{staticClass:"product-card__action product-card__action--full product-card__action--out-of-stock font_sm font_uppercase"},[_vm._v("\n      "+_vm._s(_vm.$t('shared.product.outOfStock'))+"\n    ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }