import env from '@/.env.json';

export default {
  head() {
    const scripts = []

    if(this.pageScripts?.length) {
      scripts.push(...this.pageScripts)
    }

    return {
      title: `${this.pageTitle}`,
      meta: this.getPageMetaData(),
      script: [...scripts],
    }
  },
  methods: {
    getPageMetaData() {
      if (!Array.isArray(this.pageMetaData)) return []

      return this.pageMetaData.map(meta => {
        meta.hid = meta.property || meta?.name

        return meta
      })
    },
  },
}
