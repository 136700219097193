<template>
  <a v-if="isExternalLink" :href="to" rel="noopener noreferrer" target="_blank" v-bind="$attrs">
    <slot />
  </a>
  <nuxt-link v-else :to="localePath(to)" v-bind="$attrs">
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  name: 'CustomLink',
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
  computed: {
    isExternalLink() {
      return typeof this.to === 'string' && this.to.startsWith('http');
    },
  },
};
</script>
