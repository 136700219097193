<template>
  <section class="dynamic-banner">
    <h2 class="visually-hidden">
      {{ info.name }}
    </h2>

    <template v-if="info.button">
      <CustomImage
        v-if="image.id"
        :alt="info.name"
        :aspect-ratio="image.ratio"
        :height="image.sizes[1]"
        :image-id="image.id"
        :loading="loading"
        :width="image.sizes[0]"
      />
      <!--TODO check-->
      <Button
        :class="{
          [`dynamic-banner__button--${info.button.location}`]: true,
          [`dynamic-banner__button--${info.button.type}`]: true,
        }"
        :href="buttonLink.href"
        :theme="themeButton"
        :to="localePath(buttonLink.to)"
        class="dynamic-banner__button"
      >
        {{ info.button.text }}
      </Button>
    </template>
    <CustomLink v-else :to="isExternalLink ? info.url : localePath(info.url)">
      <CustomImage
        v-if="image.id"
        :alt="info.name"
        :aspect-ratio="image.ratio"
        :height="image.sizes[1]"
        :image-id="image.id"
        :media="mediaImage"
        :width="image.sizes[0]"
      />
    </CustomLink>
  </section>
</template>

<script>
import Button from '@/components/elements/Button';
import CustomImage from '~/components/elements/CustomImage';
import CustomLink from '~/components/elements/CustomLink';

export default {
  name: 'DynamicBanner',
  components: {
    CustomImage,
    CustomLink,
    Button,
  },
  props: {
    info: { type: Object, required: true },
    loading: { type: String, default: 'lazy' },
    ratio: { type: Object, default: Object },
    sizes: { type: Object, default: Object },
  },
  data() {
    const defaultRatio = {
      mobile: '97.78%',
      tablet: '27.08%',
      pc: '27.08%',
    };

    const defaultSizes = {
      mobile: {
        width: 360,
        height: 352,
      },
      tablet: {
        width: 1200,
        height: 352,
      },
      pc: {
        width: 1200,
        height: 352,
      },
    };

    const mediaImage = [
      {
        id: this.info.mobileImageId,
        width: defaultSizes.mobile.width,
        height: defaultSizes.mobile.height,
        media: '(max-width:768px)',
      },
      {
        id: this.info.tabletImageId,
        width: defaultSizes.tablet.width,
        height: defaultSizes.tablet.height,
        media: '(max-width:1023px)',
      },
    ];

    return {
      mediaImage: mediaImage.filter((item) => item.id),

      bannerRatio: {},
      bannerSizes: defaultSizes,
      defaultRatio,
      defaultSizes,

      image: {
        id: this.info.desktopImageId,
        sizes: [defaultSizes.pc.width, defaultSizes.pc.height],
        ratio: defaultRatio.pc,
      },
    };
  },
  computed: {
    themeButton() {
      switch (this.info.button.type) {
        case 'outlineWhite':
          return 'inversion';
        case 'outlineBlack':
          return 'tertiary';
        default:
          return '';
      }
    },
    isExternalLink() {
      return this.info.url.startsWith('http');
    },
    buttonLink() {
      return {
        to: this.isExternalLink ? '' : this.info.url,
        href: this.isExternalLink ? this.info.url : '',
      };
    },
    mqIsMobileXs() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile';
    },
    mqIsTablet() {
      return this.$mq === 'tablet';
    },
  },
  watch: {
    $mq: {
      handler(value) {
        switch (value) {
          case 'mobileXs':
          case 'mobile':
            if (this.mediaImage.length) {
              this.setMediaSizes(this.info.mobileImageId, {
                width: this.bannerSizes.mobile.width,
                height: this.bannerSizes.mobile.height,
              });

              this.image.sizes = [this.bannerSizes.mobile.width, this.bannerSizes.mobile.height];
              this.image.ratio = this.bannerRatio.mobile;
            }
            break;
          case 'tablet':
            if (this.mediaImage.length) {
              this.setMediaSizes(this.info.tabletImageId, {
                width: this.bannerSizes.tablet.width,
                height: this.bannerSizes.tablet.height,
              });

              this.image.sizes = [this.bannerSizes.tablet.width, this.bannerSizes.tablet.height];
              this.image.ratio = this.bannerRatio.tablet;
            }
            break;
          default:
            this.image.sizes = [this.bannerSizes.pc.width, this.bannerSizes.pc.height];
            this.image.ratio = this.bannerRatio.pc;
        }
      },
      immediate: true,
    },
  },
  created() {
    Object.assign(this.bannerRatio, this.defaultRatio, this.ratio);
    Object.assign(this.bannerSizes, this.defaultSizes, this.sizes);
  },
  beforeMount() {
    if (this.mqIsTablet) {
      this.image.sizes = [this.bannerSizes.tablet.width, this.bannerSizes.tablet.height];
      this.image.ratio = this.bannerRatio.tablet;

      this.setMediaSizes(this.info.tabletImageId, {
        width: this.bannerSizes.mobile.width,
        height: this.bannerSizes.mobile.height,
      });
    } else if (this.mqIsMobileXs) {
      this.image.sizes = [this.bannerSizes.mobile.width, this.bannerSizes.mobile.height];
      this.image.ratio = this.bannerRatio.mobile;

      this.setMediaSizes(this.info.mobileImageId, {
        width: this.bannerSizes.tablet.width,
        height: this.bannerSizes.tablet.height,
      });
    } else {
      this.image.sizes = [this.bannerSizes.pc.width, this.bannerSizes.pc.height];
      this.image.ratio = this.bannerRatio.pc;
    }
  },
  methods: {
    setMediaSizes(id, sizes) {
      const mediaId = this.mediaImage.findIndex((item) => item.id === id);

      if (mediaId !== -1) {
        this.$set(this.mediaImage[mediaId], 'width', sizes.width);
        this.$set(this.mediaImage[mediaId], 'height', sizes.height);
      }
    },
  },
};
</script>

<style scoped>
.dynamic-banner {
  position: relative;
  width: 100%;
  margin-bottom: 32px;
  overflow: hidden;
}

.dynamic-banner >>> .dynamic-banner__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dynamic-banner__button {
  position: absolute;
}

.dynamic-banner__button--topLeft {
  top: 24px;
  left: 24px;
}

.dynamic-banner__button--top {
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.dynamic-banner__button--topRight {
  top: 24px;
  right: 24px;
}

.dynamic-banner__button--right {
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
}

.dynamic-banner__button--bottomRight {
  bottom: 24px;
  right: 24px;
}

.dynamic-banner__button--bottom {
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.dynamic-banner__button--bottomLeft {
  bottom: 24px;
  left: 24px;
}

.dynamic-banner__button--left {
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
}

@media all and (min-width: 1280px) {
  .dynamic-banner__button--topLeft {
    top: 48px;
    left: 48px;
  }

  .dynamic-banner__button--top {
    top: 48px;
  }

  .dynamic-banner__button--topRight {
    top: 48px;
    right: 48px;
  }

  .dynamic-banner__button--right {
    right: 48px;
  }

  .dynamic-banner__button--bottomRight {
    bottom: 48px;
    right: 48px;
  }

  .dynamic-banner__button--bottom {
    bottom: 48px;
  }

  .dynamic-banner__button--bottomLeft {
    bottom: 48px;
    left: 48px;
  }

  .dynamic-banner__button--left {
    left: 48px;
  }
}
</style>
